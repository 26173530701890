import React from 'react';
import Header from '../components/navigation/TopNavigation';
import { Helmet } from 'react-helmet';
import Footer from '../components/navigation/Footer';

import './about.scss';

const HowToPlay: React.FC = () => {
    return (
        <div id="AboutScreen">
            <Helmet>
                <title>GShake | How to play</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                <h1 className='about-header'> Shake to Earn Gold</h1>
                <ul>
                    <li>Shake your phone to reveal gold nuggets.</li>
                    <li>Tap to collect nuggets and earn points.</li>
                    <li>Every 10 shakes reveals more nuggets.</li>
                </ul>
                <h1 className='about-header'>Invite Friends & Earn More</h1>
                <ul>
                    <li>Invite friends to join and "hire" them.</li>
                    <li>Get a % of their earnings. <span className='comming-soon'/></li>
                    <li>Enjoy big paydays as your team grows! <span className='comming-soon'/></li>
                </ul>
                <h1 className='about-header'>Convert Points to Real Gold</h1>
                <ul>
                    <li>Later you will be able to convert points into real gold!</li>
                    <li>Shake, collect, and turn your efforts into real-world rewards!</li>
                </ul>
                <h1 className='about-header'>Level Up for Bigger Boosts</h1>
                <ul>
                    <li>Level up to unlock powerful boosts. <span className='comming-soon'/></li>
                    <li>Boosts increase shaking power and gold collection speed. <span className='comming-soon'/></li>
                    <li>Higher levels mean faster and bigger earnings! <span className='comming-soon'/></li>
                </ul>
            </div>
            <Footer />
        </div>
    );
}

export default HowToPlay;