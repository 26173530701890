import React from 'react';
import './JoinContent.scss';
import QRCode from 'react-qr-code';

const JoinContent: React.FC = () => {

    const handleJoinNow = () => {
        window.open('https://t.me/gshake_bot', '_blank');
    }

    return (
        <div className="qr-code-wrapper">
            <div className="qr-code-container">
                {/* Blurred QR code */}
                <QRCode value="https://t.me/gshake_bot" />
                {/* "Coming Soon" badge */}
                {/* <div className="coming-soon-badge">Coming Soon</div> */}
            </div>
            {/* <div className='modal-content'>
            </div> */}
            <button className="join-now-button" onClick={handleJoinNow} >Play now!</button>
        </div>
    );
};

export default JoinContent;
