import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { ReactComponent as Burger } from '../../assets/burger.svg';
import './TopNavigation.scss';
import MobileMenu from './MobileMenu';
import { ReactComponent as Telegram } from '../../assets/telegramBotton.svg';
import { ReactComponent as X } from '../../assets/xBotton.svg';


const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigation = useNavigate();

  const navigateToHome = () => {
    navigation('/');
    }

  return (
    <div className="header">
      <div className="logo">
        <img src={Logo} alt="Logo" onClick={navigateToHome}/>
      </div>
      <div className="menu">
        <NavLink 
          to="/"
          end
          className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
        >
          Home
        </NavLink>
        <NavLink 
          to="/about" 
          className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
        >
          About
        </NavLink>
        <NavLink 
          to="/rules" 
          className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
        >
          Rules
        </NavLink>
        <NavLink 
          to="/how-to-play" 
          className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
        >
          How to play
        </NavLink>
        <NavLink 
          to="/whitepaper" 
          onClick={(e) => e.preventDefault()}
          className={'menu-item disabled'}
        >
          Whitepaper
        </NavLink>
        
      </div>
      <div className="menu-mobile">
        <Burger onClick={() => setIsOpen(!isOpen)}/>
        <MobileMenu isOpen={isOpen} closeMenu={() => setIsOpen(false)} />
      </div>
      <div className="social-icons">
        <a href="https://x.com/GSHAKE_game" className="social-icon" target='_blank' rel="noreferrer"><X /></a>
        <a href="https://t.me/gshake_ann" className="social-icon" target="_blank" rel="noreferrer"><Telegram/></a>
      </div>
    </div>
  );
};

export default Header;
