import React from 'react';
import Header from '../components/navigation/TopNavigation';
import { Helmet } from 'react-helmet';
import Footer from '../components/navigation/Footer';
import Img1 from '../assets/aboutUs/img 1.png';
import Img2 from '../assets/aboutUs/img 2.png';
import Img3 from '../assets/aboutUs/img 3.png';
import Img4 from '../assets/aboutUs/img 4.png';
import Img5 from '../assets/aboutUs/img 5.png';
import Img6 from '../assets/aboutUs/img 6.png';

import './about.scss';

const HowToPlay: React.FC = () => {
    return (
        <div id="AboutScreen">
            <Helmet>
                <title>GShake | How to play</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                    <h1 className='about-header'>Navigate to the game in a telegram app</h1>
                    <div className='images-wrapper'>
                        <img src={Img1} alt='Telegram app' className='about-img'/>
                        <img src={Img2} alt='Telegram app' className='about-img'/>
                        <img src={Img3} alt='Telegram app' className='about-img'/>
                    </div>
                    <h1 className='about-header'>Start playing</h1>

                    <div className='images-wrapper'>
                        <img src={Img4} alt='Telegram app' className='about-img'/>
                        <img src={Img5} alt='Telegram app' className='about-img'/>
                        <img src={Img6} alt='Telegram app' className='about-img'/>
                    </div>
            </div>
            <Footer />
        </div>
    );
}

export default HowToPlay;