import React from 'react';
import { ReactComponent as Telegram } from '../../assets/telegram.svg';
import { ReactComponent as X } from '../../assets/x.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Mail } from '../../assets/MdMailOutline.svg';

import './Footer.scss';

const Footer: React.FC = () => {

    return (
        <footer id="Footer">
            <div className='footer-content'>
                <div className='footer-section'>
                    <Link to="/privacy-policy">Privacy policy</Link>
                    <div className='footer-section-item'>
                        <Mail />
                        <a href="mailto:hello@gshake.io" rel="noreferrer">hello@gshake.io</a>
                    </div>
                </div>
                <div className='footer-section'>
                    <div className='footer-section-item'>
                       
                        <a href="https://x.com/GSHAKE_game" target="_blank" rel="noreferrer"><X /></a>
                    </div>
                    <div className='footer-section-item'>
                  
                        <a href="https://t.me/gshake_ann" target="_blank" rel="noreferrer"><Telegram /></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;