import React, { useState, useEffect } from 'react';
import QRContainer from '../components/home/QRContainer';
import TopNavigation from '../components/navigation/TopNavigation';
import Footer from '../components/navigation/Footer';
import { Helmet } from 'react-helmet';

import "./home.scss";

const HomeScreen: React.FC = () => {
    const [shake, setShake] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShake(true); // Start shaking
            setTimeout(() => setShake(false), 500); // Stop shaking after 0.5 seconds
        }, 5000); // Trigger every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div id="HomeScreen" className={shake ? 'shake' : ''}>
            <Helmet>
                <title>GShake | Home</title>
            </Helmet>
            <TopNavigation />
            <QRContainer />
            <Footer />
        </div>
    );
}

export default HomeScreen;
