import React from 'react';
import Header from '../components/navigation/TopNavigation';

import './about.scss';
import Footer from '../components/navigation/Footer';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {
    return (
        <div id="AboutScreen">
              <Helmet>
                <title>GShake | About Us</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                <h1 className='about-header'>Welcome to GShake!</h1>
                <h2>The first shake to earn, backed by real gold game on Telegram</h2>
                <p>GShake brings the thrill of the 19th-century Gold Rush straight to your phone. By shaking, players earn in-game points that can be converted into cryptocurrency backed by real gold reserves.</p>
                <p>It’s more than just a game; it’s an immersive financial experience where gameplay meets real-world value and as the community grows so does the gold in the vaults.</p>
                <h2 style={{marginTop: '20px', marginBottom: "-20px"}}>Our Mission</h2>
                <p>To create an engaging and rewarding experience for players while delivering real value through gold-backed crypto assets.</p>
            </div>
            <Footer />
        </div>
    );
}

export default About;