import React from 'react';
import Header from '../components/navigation/TopNavigation';
import { Helmet } from 'react-helmet';
import Footer from '../components/navigation/Footer';

import './about.scss';

const PrivacyPolicy: React.FC = () => {
    return (
        <div id="AboutScreen">
            <Helmet>
                <title>GShake | Privacy Policy</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                <h1 className='about-header'>Privacy Policy</h1>
                <p>gshake.io is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website gshake.io. Please read this Privacy Policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
                <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the "Effective Date" of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.</p>
                <p>1. Information We Collect </p>
                <p>
                We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
                <p>a) Personal Data </p>
                <p>
                Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, and location, that you voluntarily give to us when you register with the Site, place an order, or choose to participate in various activities related to the Site.</p>
                <p>b) Derivative Data </p>
                <p>
                Information our servers automatically collect when you access the Site, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.</p>
                <p>c) Financial Data</p>
                <p>
                Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, or request information about our services from the Site.</p>
                <p>d) Cookies and Tracking Technologies</p>
                <p>
                We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. For more information on how we use cookies, please refer to our Cookie Policy.</p>
                <p>2. Use of Your Information</p>
                <p>
                We may use the information we collect from you in the following ways:</p>
                <ul>
                    <li>To provide, operate, and maintain our website and services</li>
                    <li>To process your transactions and manage your orders</li>
                    <li>To send administrative information such as confirmations, invoices, updates, security alerts, and support messages</li>
                    <li>To improve the Site and our products/services</li>
                    <li>To monitor and analyze usage and trends to improve your experience</li>
                    <li>To notify you about changes to our services</li>
                    <li>To prevent fraudulent transactions, monitor against theft, and protect against criminal activity</li>
                    <li>To enforce our terms and conditions and other legal agreements</li>
                </ul>
                <p>3. Sharing Your Information</p>
                <p>We may share the information we collect in the following ways:</p>
                <ul>
                    <li>With service providers who help us operate the Site, such as hosting services, analytics, and payment processors</li>
                    <li>With third-party vendors for marketing, promotions, and advertising purposes</li>
                    <li>To comply with legal obligations, such as responding to subpoenas, court orders, or other legal processes</li>
                    <li>With law enforcement or regulatory authorities as required to ensure compliance with the law or to protect our rights and interests</li>
                </ul>
                <p>4. Security of Your Information</p>
                <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                <p>5. Your Rights and Choices</p>
                <p>You have certain rights regarding your personal data, including:</p>
                <ul>
                    <li>The right to access the personal information we have collected from you</li>
                    <li>The right to request correction of inaccurate data</li>
                    <li>The right to request deletion of your data</li>
                    <li>The right to restrict or object to the processing of your personal information</li>
                    <li>The right to withdraw your consent at any time</li>
                </ul>
                <p>6. Data Retention</p>
                <p>We will retain your personal information for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
                <p>7. Third-Party Websites</p>
                <p>The Site may contain links to third-party websites and services that are not affiliated with us. We are not responsible for the content, privacy, or security practices of any third-party website. We encourage you to review the privacy policies of those websites.</p>
                <p>8. Children's Privacy</p>
                <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.</p>
                <p>9. Contact Us</p>
                <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
                <a href="mailto:hello@gshake.io" rel="noreferrer">hello@gshake.io</a>
                <a href="gshake.io" target="_blank" rel="noreferrer">gshake.io</a>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;