import React from 'react';
import JoinContent from './JoinContent';

import "./QRContainer.scss";

const QRContainer: React.FC = () => {

    return (
        <div id="QRContainer">
            {/* <div className="social-icons">
                <a href="https://x.com/GSHAKE_game" className="social-icon" target='_blank' rel="noreferrer">X</a>
                <a href="https://t.me/gshake_news" className="social-icon">Telegram</a>
            </div> */}
            <div className="main-qr-wrapper-container">
                <div className="qr-wrapper-side">not  a clicker.</div>
                <div className='qr-wrapper'>
                    <JoinContent />
                </div>
                <div className="qr-wrapper-side right" >shaker.</div>
            </div>
        </div>
    );
}

export default QRContainer;
